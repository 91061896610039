import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import Modal from 'components/modal/Modal'
import Category from 'components/label/Category'
import Button from 'components/button/Button'

import AnimationDetailContent from './AnimationDetailContent'
import RegisterDrawerContent from './RegisterDrawerContent'
import RegisterSharingContent from './RegisterSharingContent'

import useI18n from 'i18n/useI18n'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'

import { findSlot, getChildName, getDateString } from './utils'
import Logger from 'utils/Logger'
import { isBefore, differenceInYears, parse, isAfter } from 'date-fns'
import { dateStringToZonedTime } from 'utils/dateUtils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  detail: AnimationDetail
  reservation: Booking
  setMode: (mode: RegisterDetailMode) => void
}

const RegisterDetailContent = ({ detail, reservation, setMode }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const [disableActions, setDisableActions] = React.useState(false)

  const reservationTimeLabel = `${getDateString(i18n, reservation.startDate)} • ${i18n.t(
    'screens.register.detail.timeSlot',
    {
      start: dateStringToZonedTime(reservation.startDate),
      end: dateStringToZonedTime(reservation.endDate),
    }
  )}`

  const isFamilyDay = reservation.status === 'FAMILY'

  const findSlotBooking = React.useMemo(() => detail?.slots.find((s) => s.id === reservation?.slotId), [
    detail,
    reservation,
  ])

  const isAfterMaxDate = React.useMemo(
    () => (!!findSlotBooking ? isAfter(new Date(), new Date(findSlotBooking.endDateInscription)) : undefined),
    [findSlotBooking]
  )

  const errorAlert = (err: any) => {
    Logger.error(err)
    Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
  }

  const cancelReservation = () => {
    if (!disableActions) {
      setDisableActions(true)
      Alert.open({
        title: i18n.t('screens.register.detail.removeBooking'),
        children: <AnimationDetailContent reservation={reservation} small />,
        buttons: [
          {
            label: i18n.t('common.cancel'),
            onClick: () => {
              Alert.close()
              setDisableActions(false)
            },
            style: 'secondary',
          },
          {
            label: i18n.t('common.delete'),
            onClick: () => {
              analytics.event({
                event_feature: values.eventName.register,
                event_action: values.actions.deleteBooking,
                event_object_id: reservation.id,
              })
              api
                .cancel(detail.id, reservation.slotId, reservation.id)
                .then(() => {
                  Alert.close()
                  Alert.open({
                    title: i18n.t('common.success'),
                    description: i18n.t('screens.myReservations.deleteSucceed'),
                  })
                  navigation.push('/register')
                })
                .catch((err) => {
                  Logger.error(err)
                  Alert.close()
                  Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
                })
                .finally(() => setDisableActions(false))
            },
          },
        ],
      })
    } else {
      errorAlert(`bookingId: ${reservation.id}, slotId: ${reservation.slotId}`)
      setDisableActions(false)
    }
  }

  const openInvitationDrawer = (booking: Booking, qrCode: string, displayName?: string) =>
    Modal.open({
      Content: () => (
        <RegisterDrawerContent
          type={detail?.invitationType || 'QR_CODE'}
          cardType={findSlot(booking.slotId ?? '', detail?.slots ?? [])?.invitationCardType || undefined}
          booking={booking}
          qrCode={qrCode}
          displayName={displayName}
        />
      ),
    })

  const openSharingDrawer = (booking: Booking) =>
    Modal.open({ Content: () => <RegisterSharingContent booking={booking} /> })

  const renderSlotInfos = () => (
    <SlotContainer>
      <RowContainer>
        <SlotInfo>
          <SlotTime>{reservationTimeLabel}</SlotTime>
          {isFamilyDay && (
            <SlotTime marginTop={8} subtitle>
              {reservation.slotPlace}
            </SlotTime>
          )}
          {!!reservation.sharedBy ? (
            <SharedInfoContainer>
              <Category
                icon="people"
                label={i18n.t('screens.register.detail.sharing.sharedBy', { name: reservation.sharedBy })}
                font="label"
              />
            </SharedInfoContainer>
          ) : (
            !!reservation.sharedToUsers && (
              <SharedInfoContainer>
                <Category
                  icon="people"
                  label={i18n.t('screens.register.detail.sharing.sharedTo', {
                    name: reservation.sharedToUsers.filter(Boolean).join(', '),
                  })}
                  font="label"
                />
              </SharedInfoContainer>
            )
          )}
        </SlotInfo>

        {!isFamilyDay && (
          <IconContainer onClick={() => openInvitationDrawer(reservation, reservation.qrcode)}>
            <Icon
              name={detail.invitationType === 'QR_CODE' ? 'qrcode' : 'invitation'}
              size={24}
              color={theme.colors.primaryDark}
              cursor="pointer"
            />
          </IconContainer>
        )}

        {!reservation.sharedBy && !isBefore(new Date(reservation.endDate), new Date()) && !isAfterMaxDate && (
          <>
            {detail.transferredInvitation && (
              <IconContainer onClick={() => openSharingDrawer(reservation)}>
                <Icon name="transfer" size={24} color={theme.colors.primaryDark} cursor="pointer" />
              </IconContainer>
            )}

            <IconContainer onClick={() => setMode('MODIFY')}>
              <Icon name="pencil" size={24} color={theme.colors.primaryDark} cursor="pointer" />
            </IconContainer>
            <IconContainer onClick={cancelReservation}>
              <Icon name="cross" size={24} color={theme.colors.primaryDark} cursor="pointer" />
            </IconContainer>
          </>
        )}
      </RowContainer>
      {isFamilyDay && renderFamilyDayInfos()}
    </SlotContainer>
  )

  const renderFamilyDayInfos = () => {
    if (!reservation.familyInformationForm || reservation.familyInformationForm.children.length === 0) {
      return (
        <ButtonContainer>
          <Button
            label={i18n.t('screens.register.detail.familyInfos.fillForm')}
            onClick={() =>
              navigation.push(`/register/family/${reservation.animationId}/${reservation.slotId}/${reservation.id}`)
            }
          />
        </ButtonContainer>
      )
    }
    const {
      parentFirstname,
      parentLastname,
      parentPhone,
      lunchWithChildren,
      isGroupSupervisor,
      delegatedPerson,
      children,
    } = reservation.familyInformationForm
    const withTwoOptions = isGroupSupervisor && lunchWithChildren
    return (
      <FamilyDayInfosContainer>
        <Separator />
        <SlotTime bold marginTop={12}>
          {i18n.t('screens.register.detail.familyInfos.parent')}
        </SlotTime>
        <RowContainer marginTop={8} gap={8}>
          <SlotTime>
            {parentFirstname} {parentLastname}
          </SlotTime>
          <PointSeparator />
          <SlotTime>{parentPhone}</SlotTime>
        </RowContainer>
        <RowContainer marginTop={8} gap={8}>
          {isGroupSupervisor && <SlotTime>{i18n.t('screens.register.detail.familyInfos.groupSupervisor')}</SlotTime>}
          {withTwoOptions && <PointSeparator />}
          {lunchWithChildren && <SlotTime>{i18n.t('screens.register.detail.familyInfos.lunchWithChildren')}</SlotTime>}
        </RowContainer>
        {!!delegatedPerson &&
          !!delegatedPerson.firstname &&
          !!delegatedPerson.lastname &&
          !!delegatedPerson.phone &&
          !!delegatedPerson.childLink && (
            <>
              <SlotTime bold marginTop={16}>
                {i18n.t('screens.register.detail.familyInfos.legalRepresentative')}
              </SlotTime>
              <RowContainer marginTop={8} gap={8}>
                <SlotTime>
                  {delegatedPerson.firstname} {delegatedPerson.lastname}
                </SlotTime>
                <PointSeparator />
                <SlotTime>{delegatedPerson.phone}</SlotTime>
                <PointSeparator />
                <SlotTime>{delegatedPerson.childLink}</SlotTime>
              </RowContainer>
            </>
          )}
        <Separator marginTop={16} />
        {children.map(renderChildren)}
      </FamilyDayInfosContainer>
    )
  }

  const renderChildren = (children: ChildFormQRCode, index: number) => {
    const birthDate = parse(children.childBirthDate, 'dd-MM-yyyy', new Date())
    const age = differenceInYears(new Date(), birthDate)
    return (
      <RowContainer marginTop={12}>
        <SlotInfo>
          <SlotTime bold>
            {i18n.t('screens.register.detail.familyForm.childNumber', {
              count: index + 1,
            })}
          </SlotTime>
          <RowContainer marginTop={8} gap={8}>
            <SlotTime>
              {children.childFirstname} {children.childLastname}
            </SlotTime>
            <PointSeparator />
            <SlotTime>
              {i18n.t('screens.register.detail.familyInfos.age', {
                count: age,
              })}
            </SlotTime>
            {children.additionalInformation && (
              <>
                <PointSeparator />
                <SlotTime>{children.additionalInformation}</SlotTime>
              </>
            )}
          </RowContainer>
        </SlotInfo>
        <IconContainer onClick={() => openInvitationDrawer(reservation, children.qrCode, getChildName(children))}>
          <Icon
            name={detail.invitationType === 'QR_CODE' ? 'qrcode' : 'invitation'}
            size={24}
            color={theme.colors.primaryDark}
            cursor="pointer"
          />
        </IconContainer>
      </RowContainer>
    )
  }

  return renderSlotInfos()
}

export default RegisterDetailContent

const SlotContainer = styled('div')`
  display: flex;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 12px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  margin-top: 32px;
`

const RowContainer = styled('div')<{ marginTop?: number; gap?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}
  ${(props) => props.gap && `gap: ${props.gap}px;`}
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled('p')<{ bold?: boolean; marginTop?: number; subtitle?: boolean }>`
  ${(props) =>
    props.bold ? props.theme.fonts.bodyBold : props.subtitle ? props.theme.fonts.subtitle : props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 0;
  padding: 0;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}
`

const SharedInfoContainer = styled('div')`
  margin-top: 6px;
`

const IconContainer = styled('div')`
  padding: 7px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  margin-left: 12px;
  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    cursor: pointer;
  }
`

const FamilyDayInfosContainer = styled('div')`
  padding: 16px 0;
`

const Separator = styled('div')<{ marginTop?: number }>`
  background-color: ${(props) => props.theme.colors.disable};
  height: 1px;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}
`

const PointSeparator = styled('div')`
  height: 3px;
  width: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.primaryText};
`

const ButtonContainer = styled('div')`
  padding-top: 24px;
  align-items: center;
`
