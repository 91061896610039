import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

import { getDateString } from './utils'
import { breakpoints } from 'utils/breakpoints'
import { dateStringToZonedTime } from 'utils/dateUtils'

interface Props {
  detail?: AnimationDetail
  reservation?: Booking
  slot?: Slot
  email?: string
  small?: boolean
}

const AnimationDetailContent = ({ detail, reservation, slot, email, small }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const name = detail?.name || reservation?.animationName
  const place = slot?.place || reservation?.slotPlace || detail?.place
  const image = detail?.imageUrl || reservation?.animationImage
  const start = slot?.startDate || reservation?.startDate || detail?.slotFirstDate
  const end = slot?.endDate || reservation?.endDate || detail?.slotLastDate
  const isSlot = !!slot || !!reservation

  const animationTimeLabel = React.useMemo(() => (!!start ? getDateString(i18n, start, end) : ''), [start, end])

  if (small || isSlot) {
    return (
      <>
        <SmallImageAndScheduleContainer>
          <SmallInfoImage src={image || require('core/src/assets/news_default.jpeg').default} />
          <SmallInfosContainerMain>
            <Subtitle fontSize={15}>{`${animationTimeLabel} - ${place}`}</Subtitle>
            {!!name && (
              <Subtitle bold marginTop={8}>
                {name}
              </Subtitle>
            )}
          </SmallInfosContainerMain>
        </SmallImageAndScheduleContainer>

        {isSlot && (
          <SlotTimeContainer>
            <Subtitle bold>
              {i18n.t('screens.home.featureDetail.timeSlot', {
                start: dateStringToZonedTime(start!),
                end: dateStringToZonedTime(end!),
              })}
            </Subtitle>
            {!!email && <Subtitle>{i18n.t('screens.register.detail.collaborator.forEmail', { email })}</Subtitle>}
          </SlotTimeContainer>
        )}
      </>
    )
  }

  return (
    <ImageAndScheduleContainer>
      <InfoImage src={image || require('core/src/assets/news_default.jpeg').default} />
      <InfosContainerMain>
        <InfosContainer>
          <Icon name="agenda_filled" size={32} color={theme.colors.primaryDark} />
          <Subtitle marginTop={12}>{animationTimeLabel}</Subtitle>
        </InfosContainer>
        <InfosContainer>
          <Icon name="pin_alt" size={32} color={theme.colors.primaryDark} />
          <Subtitle marginTop={12}>{place}</Subtitle>
        </InfosContainer>
      </InfosContainerMain>
    </ImageAndScheduleContainer>
  )
}

export default AnimationDetailContent

// Common design

const Subtitle = styled('p')<{ marginTop?: number; bold?: boolean; fontSize?: number }>`
  ${(props) => (props.bold ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  font-size: ${(props) => props.fontSize || 16}px;
  margin-top: ${(props) => props.marginTop || 0}px;
`

// Regular design

const ImageAndScheduleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const InfosContainerMain = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: row;
  }
`

const InfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.contentBackground};
  padding: 16px;
  max-width: 218px;
  height: auto;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    max-width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    max-width: 100%;
  }
`

const InfoImage = styled('img')`
  object-fit: cover;
  user-select: none;
  border-radius: 12px;
  aspect-ratio: 4/3;
  height: 300px;
  width: 400px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 250px;
    width: 100%;
  }
`

// Small design

const SmallImageAndScheduleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
`

const SmallInfosContainerMain = styled('div')`
  display: flex;
  flex-direction: column;
`

const SmallInfoImage = styled('img')`
  object-fit: cover;
  user-select: none;
  border-radius: 4px;
  aspect-ratio: 4/3;
  height: 50px;
  width: 66px;
`

// Slot info

const SlotTimeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 14px;
  border-radius: 12px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.contentBackground};
`
