import * as React from 'react'
import Provider from 'theme/Provider'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import useI18n from 'i18n/useI18n'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'
import Icon from '../icons/Icon'
import Checkbox from 'components/button/Checkbox'

interface AlertButton {
  label: string
  onClick: (checkBoxStatus?: string) => void
  style?: ButtonStyle
  canBeDisabled?: boolean
}

interface CheckBoxInfos {
  id: string
  label: string
  isLabelHtml?: boolean
  textAlign?: 'left' | 'right' | 'center'
}

interface Props {
  titleIcon?: IconName
  iconColor?: string
  title: string
  description?: string
  isDescriptionHtml?: boolean
  buttons?: AlertButton[]
  children?: JSX.Element
  checkBoxList?: CheckBoxInfos[]
}

export const ALERT_WIDTH = 500

const Alert = ({
  title,
  description,
  isDescriptionHtml,
  buttons,
  children,
  titleIcon,
  iconColor,
  checkBoxList,
}: Props) => {
  const i18n = useI18n()

  const [checkBoxSelected, setCheckBoxSelected] = React.useState<CheckBoxInfos>()

  useEscKeyPressed(AlertPortal.close)

  const renderButton = (item: AlertButton) => (
    <ButtonContainer key={item.label}>
      <Button
        label={item.label}
        onClick={() => item.onClick(!!checkBoxList && !!checkBoxSelected ? checkBoxSelected.id : undefined)}
        style={item.style}
        disabled={!!item.canBeDisabled && !!checkBoxList && !checkBoxSelected}
      />
    </ButtonContainer>
  )

  const renderCheckBox = (item: CheckBoxInfos) => (
    <CheckBoxContainer key={item.id}>
      <Checkbox
        active={item.id === checkBoxSelected?.id}
        ariaLabel=""
        onClick={() => setCheckBoxSelected(item)}
        label={item.label}
        textAlign={item.textAlign}
        isLabelHtml={item.isLabelHtml}
      />
    </CheckBoxContainer>
  )

  return (
    <MainContainer>
      <Back />

      <FocusLock autoFocus={true} returnFocus>
        <Container id="AlertDialog" role="dialog" aria-modal="true" aria-labelledby="modalAlertHeading" tabIndex={-1}>
          <TitleContainer noMargin={!description && !checkBoxList && !children}>
            {!!titleIcon && <Icon name={titleIcon} size={30} color={iconColor} />}
            <Title id="modalAlertHeading" paddingLeft={!!titleIcon}>
              {title}
            </Title>
          </TitleContainer>

          {description &&
            (isDescriptionHtml ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <Description>{description}</Description>
            ))}

          {!!checkBoxList && <Separator />}

          {!!checkBoxList && checkBoxList.map(renderCheckBox)}

          {!!children && children}

          <ButtonsContainer>
            {buttons
              ? buttons.map(renderButton)
              : renderButton({ label: i18n.t('common.ok'), onClick: AlertPortal.close })}
          </ButtonsContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  border-radius: 12px;
  max-height: 80vh;
  width: ${ALERT_WIDTH}px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.primaryDark}21;
  padding: 40px 32px;
  overflow-y: auto;

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: auto;
    margin: 0px 24px;
  }
`

const Title = styled('h1')<{ paddingLeft?: boolean }>`
  ${(props) => props.theme.fonts.h2Bold};
  text-align: center;
  ${(props) => props.paddingLeft && 'padding-left: 16px;'}
`

const Description = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 0px 0px 30px;
  text-align: center;
  word-break: break-word;
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 17px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    flex-direction: column;
  }
`

const TitleContainer = styled('div')<{ noMargin: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 0px ${(props) => (props.noMargin ? 0 : 36)}px;
`

const Separator = styled('div')`
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const CheckBoxContainer = styled('div')`
  margin-bottom: 20px;
`

const ButtonContainer = styled('div')``

let modalRoot: HTMLElement | null

const AlertPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('alert_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Alert {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default AlertPortal
