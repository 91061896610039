import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Main from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Tree from 'components/button/Tree'
import Loader from 'components/status/Loader'
import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import WarningLabel from 'components/label/WarningLabel'
import Button from 'components/button/Button'

import SlotsList from './SlotsList'
import RegisterEmailInput from './RegisterEmailInput'
import AnimationDetailContent from './AnimationDetailContent'
import RegisterDetailContent from './RegisterDetailContent'
import RegisterContactMail from './RegisterContactMail'
import RegisterBookingModals from './RegisterBookingModals'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as RegisterStore from './store'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'

import { fetchAnimations, fetchReservations, isAnimationFull, isAnimationRestricted, CONTENT_WIDTH } from './utils'
import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'
import { isBefore, isAfter, endOfDay } from 'date-fns'
import { dateStringToZonedTime } from 'utils/dateUtils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
  bookingId?: string
}

const RegisterDetailScreen = ({ id, bookingId }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const userEmail = useReducer(UserStore.store, (s) => s.user)?.mail
  const allReservations = useReducer(RegisterStore.store, (s) => s.reservations).concat(
    useReducer(RegisterStore.store, (s) => s.pastReservations)
  )
  const animationUser = useReducer(RegisterStore.store, (s) => s.animationUser)
  const emailData = useReducer(RegisterStore.store, (s) => s.delegatedEmailUser)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [statusUser, setStatusUser] = React.useState<ScreenStatus>('loading')
  const [pageStatus, setPageStatus] = React.useState<ScreenStatus>('loading')
  const [mode, setMode] = React.useState<RegisterDetailMode>(!!bookingId ? 'DETAIL' : 'BOOK')
  const [detail, setDetail] = React.useState<AnimationDetail>()
  const [subscribed, setSubscribed] = React.useState(false)

  // Inscription d'un autre collaborateur
  const [isDelegated, setIsDelegated] = React.useState(false)

  // On affichera les créneaux uniquement si l'utilisateur / le collaborateur est éligible,
  // ou si l'utilisateur est en train de modifier une réservation existante
  const isEligible = React.useMemo(
    () =>
      isDelegated
        ? !!emailData?.data?.eligible
        : isAnimationRestricted(detail)
        ? !!animationUser?.eligible || (mode === 'MODIFY' && animationUser?.error_type === 'ALREADY_REGISTER')
        : true,
    [mode, isDelegated, animationUser, emailData, detail]
  )

  // Liste des autres réservations de l'animation
  const alreadyBooked = React.useMemo(
    () =>
      isDelegated
        ? []
        : allReservations
            .filter((r) => r.animationId === id && (r.status === 'BOOKED' || r.status === 'FAMILY'))
            .sort((a, b) => a.startDate.localeCompare(b.startDate)),
    [id, allReservations, isDelegated]
  )

  const reservation = React.useMemo(() => allReservations.find((r) => r.id === bookingId), [allReservations, bookingId])

  const couldBookNewSlot =
    (!!detail && (detail.multipleSlotByUser || detail.multipleSlotByDay)) || alreadyBooked.length === 0

  const findSlotBooking = React.useMemo(() => detail?.slots.find((s) => s.id === reservation?.slotId), [
    detail,
    reservation,
  ])

  const isAfterMaxDate = React.useMemo(
    () => (!!findSlotBooking ? isAfter(new Date(), new Date(findSlotBooking.endDateInscription)) : undefined),
    [findSlotBooking]
  )

  // On vérifie s'il existe au moins un créneau valide, sinon l'animation est complète
  const isFull = React.useMemo(() => !!detail && isAnimationFull(detail) && (!findSlotBooking || isAfterMaxDate), [
    detail,
    bookingId,
  ])

  React.useEffect(() => {
    if (status === 'loading' || (isAnimationRestricted(detail) && statusUser === 'loading')) {
      setPageStatus('loading')
    } else if (status === 'error' || (isAnimationRestricted(detail) && statusUser === 'error')) {
      setPageStatus('error')
    } else {
      setPageStatus('ok')
    }
  }, [statusUser, status, detail])

  React.useEffect(() => {
    if (!!userEmail) {
      setStatusUser('loading')
      api
        .getUserInfo(id, userEmail)
        .then((user) => {
          RegisterStore.actions.setAnimationUser(user)
          setStatusUser('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatusUser(isAnimationRestricted(detail) ? 'error' : 'ok')
        })
    }
  }, [userEmail, id, detail])

  React.useEffect(() => {
    fetchAnimations(site?.id)
    fetchReservations(false)
    fetchReservations(true)
  }, [])

  React.useEffect(() => {
    if (!isDelegated) {
      RegisterStore.actions.resetDelegatedEmailUser()
    }
  }, [isDelegated])

  React.useEffect(() => {
    if (!bookingId) {
      analytics.screen({
        screen_feature: values.screens.register,
        screen_name: values.screens.registerBooking,
        screen_object_id: id,
      })
      setMode('BOOK')
    } else {
      analytics.screen({
        screen_feature: values.screens.register,
        screen_name: values.screens.registerDetail,
        screen_object_id: bookingId,
      })
    }
    if (allReservations.length === 0) {
      // Si on arrive directement sur le détail (depuis un lien), il faut récupérer la liste des réservations
      fetchReservations(false)
    }
  }, [bookingId])

  React.useEffect(() => {
    if (!!reservation && !['BOOKED', 'SHARED', 'FAMILY'].includes(reservation.status)) {
      // La réservation a été annulée
      setStatus('error')
      navigation.push('/register')
      Alert.open({
        title: i18n.t('common.error'),
        description: i18n.t(`screens.myReservations.status.${reservation.status}`),
      })
    }
  }, [reservation])

  React.useEffect(() => {
    if (!!id) {
      setStatus('loading')
      api
        .getDetail(id)
        .then((detail) => {
          if (
            (detail.status !== 'CREATED' && detail.status !== 'FINISHED') ||
            isBefore(new Date(), new Date(detail.publicationDate))
          ) {
            // La page de détail et la réservation ne devraient pas être accessibles
            setStatus('error')
            navigation.push('/register')
            Alert.open({
              title: i18n.t('common.error'),
              description: i18n.t(`screens.register.detail.statusError.${detail.status || 'CANCELLED'}`),
            })
          } else {
            setDetail(detail)
            setStatus('ok')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [id])

  React.useEffect(() => {
    if (!!id && !!userEmail) {
      api
        .getAlert(id)
        .then(({ alertList }) => setSubscribed(alertList.includes(userEmail)))
        .catch(Logger.error)
    }
  }, [id, userEmail])

  const onSlotSelect = (slot: Slot) => {
    // Processus de réservation : [Formulaire Family Day] -> [Droits à l'image] -> [Confirmation de réservation]
    if (detail?.animationType === 'FAMILY_DAY') {
      // On redirige vers le formulaire du Family Day
      navigation.push(`/register/family/${detail.id}/${slot.id}${!!reservation ? '/' + reservation.id : ''}`)
    } else if (!!detail && !isFull && isEligible) {
      // Affichage des pop-ups de droits à l'image puis de confirmation
      RegisterBookingModals.open({
        navigation,
        i18n,
        detail,
        slot,
        reservation,
        delegatedEmail: !!emailData?.data?.eligible ? emailData?.email : undefined,
        siteId: site?.id,
      })
    } else {
      Alert.open({
        title: i18n.t('screens.register.detail.bookResult.bookFailed'),
        description: i18n.t('screens.register.detail.bookResult.modifyError'),
      })
    }
  }

  const errorAlert = (err: any) => {
    Logger.error(err)
    Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
  }

  const subscribe = () =>
    api
      .subscribe(id)
      .then(() => setSubscribed(true))
      .catch(errorAlert)

  const unsubscribe = () =>
    api
      .unsubscribe(id)
      .then(() => setSubscribed(false))
      .catch(errorAlert)

  const screenTitle = isDelegated
    ? i18n.t('screens.register.detail.collaborator.bookFor')
    : mode === 'BOOK'
    ? i18n.t('screens.register.detail.title')
    : mode === 'DETAIL'
    ? i18n.t('screens.myReservations.detailTitle')
    : i18n.t('screens.myReservations.modifyTitle')

  const renderAlreadyBooked = (prevBooking: Booking) => {
    const bookingTime = i18n.t('screens.register.detail.dateSlot', {
      start: dateStringToZonedTime(prevBooking.startDate),
      end: dateStringToZonedTime(prevBooking.endDate),
    })
    return (
      <AlreadyBookedItem key={prevBooking.id}>
        <AlreadyBookedLabel>
          {i18n.t('screens.register.detail.alreadyBooked')}
          <AlreadyBookedValue onClick={() => navigation.push(`/register/${id}/${prevBooking.id}`)}>
            {bookingTime}
          </AlreadyBookedValue>
        </AlreadyBookedLabel>
      </AlreadyBookedItem>
    )
  }

  const renderSubscribeButton = () => {
    if (
      !!detail &&
      detail.alert &&
      detail.slots.length > alreadyBooked.length &&
      (detail.multipleSlotByUser || alreadyBooked.length === 0)
    ) {
      // On n'affiche le bouton "Me prévenir" que s'il existe au moins un créneau non réservé par l'utilisateur
      // et que l'utilisateur pourrait réserver un créneau sur cette animation (non réservée, ou multiples créneaux acceptés)

      return (
        <SubscribeContainer>
          {subscribed && (
            <SubscribeDescriptionContainer>
              <SubscribeDescriptionLabel>{i18n.t('screens.register.push.description')}</SubscribeDescriptionLabel>
            </SubscribeDescriptionContainer>
          )}
          <SubscribeButton onClick={() => (subscribed ? unsubscribe() : subscribe())}>
            <Icon name={subscribed ? 'unsubscribe' : 'subscribe'} size={24} color={theme.colors.coral} />
            <SubscribeLabel>
              {i18n.t(`screens.register.push.${subscribed ? 'unsubscribe' : 'subscribe'}`)}
            </SubscribeLabel>
          </SubscribeButton>
        </SubscribeContainer>
      )
    }
  }

  const renderRestrictedWarning = () => (
    <WarningContainer>
      <WarningLabel
        icon="padlock"
        title={i18n.t(`screens.register.detail.restricted.${isDelegated ? 'collaborator' : 'user'}`)}
        description={
          isDelegated && !emailData?.data
            ? i18n.t('screens.register.detail.restricted.unknown')
            : isDelegated && emailData?.data?.error_type === 'ALREADY_REGISTER'
            ? i18n.t('screens.register.detail.restricted.alreadyBookCollaborator')
            : !isDelegated && animationUser?.error_type === 'ALREADY_REGISTER'
            ? i18n.t('screens.register.detail.restricted.alreadyBookUser')
            : i18n.t('screens.register.detail.restricted.description', {
                category: i18n.t(
                  `screens.register.detail.restricted.category.${
                    isDelegated ? emailData?.data?.error_type?.toLowerCase() : animationUser?.error_type?.toLowerCase()
                  }`
                ),
              })
        }
      />
    </WarningContainer>
  )

  const renderPastDeadline = () => (
    <>
      <PastDeadlineContainer>
        <Icon name="suitcase" size={24} color={theme.colors.warning} />
        <PastDeadlineInfosContainer>
          <PastDeadLineTitle>{i18n.t('screens.register.detail.pastDeadline')}</PastDeadLineTitle>
          <PastDeadLineSubtitle>{i18n.t('screens.register.detail.pastDeadlineDescription')}</PastDeadLineSubtitle>
        </PastDeadlineInfosContainer>
      </PastDeadlineContainer>
      <RegisterContactMail contactMail={detail?.contactMail} />
    </>
  )

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={screenTitle} />

        <Tree
          previousPages={
            isDelegated
              ? [
                  { url: 'register', title: 'screens.register.title' },
                  { url: `register/${id}`, title: detail?.name || '' },
                ]
              : [{ url: 'register', title: 'screens.register.title' }]
          }
          currentPageTitle={isDelegated ? i18n.t('screens.register.detail.collaborator.bookFor') : detail?.name || ''}
        />

        <ContentContainer>
          {pageStatus === 'loading' ? (
            <Loader />
          ) : pageStatus === 'error' || !detail ? (
            <ErrorContainer>
              <Error>{i18n.t('common.errorDescription')}</Error>
            </ErrorContainer>
          ) : (
            <Content>
              {isDelegated && <ScreenTitle>{i18n.t('screens.register.detail.collaborator.bookingFor')}</ScreenTitle>}

              <AnimationDetailContent detail={detail} small={isDelegated} />

              {isDelegated && <RegisterEmailInput animationId={id} />}

              {!isEligible && mode !== 'DETAIL' && (!isDelegated || !!emailData?.email) && renderRestrictedWarning()}

              {!isDelegated && (
                <>
                  <Title>{detail.name}</Title>
                  <Resume dangerouslySetInnerHTML={{ __html: detail.description }} />
                </>
              )}

              {mode !== 'DETAIL' ? (
                <>
                  {((couldBookNewSlot && (!isAnimationRestricted(detail) || animationUser?.eligible)) ||
                    detail.delegatedInvitation) && (
                    <SlotsTitleContainer>
                      {isEligible && couldBookNewSlot && (
                        <SlotsTitle>
                          {isFull
                            ? i18n.t('screens.register.detail.noAvailableSlot')
                            : i18n.t('screens.register.detail.bookSlot')}
                        </SlotsTitle>
                      )}
                      {!isFull && detail.delegatedInvitation && mode === 'BOOK' && !isDelegated && (
                        <Button
                          label={i18n.t('screens.register.detail.collaborator.bookFor')}
                          onClick={() => setIsDelegated(true)}
                          style="small"
                        />
                      )}
                    </SlotsTitleContainer>
                  )}

                  {mode === 'BOOK' && alreadyBooked.length > 0 && (
                    <AlreadyBookedContainer>
                      {alreadyBooked
                        .filter(
                          (a) => !detail.multipleSlotByUser || isBefore(new Date(), endOfDay(new Date(a.endDate)))
                        )
                        .map(renderAlreadyBooked)}
                    </AlreadyBookedContainer>
                  )}

                  {isFull ? (
                    renderSubscribeButton()
                  ) : (
                    <SlotsList
                      detail={detail}
                      alreadyBooked={alreadyBooked}
                      mode={mode}
                      isEligible={isEligible}
                      bookingId={bookingId}
                      couldBookNewSlot={couldBookNewSlot}
                      onSlotSelect={onSlotSelect}
                      slotId={reservation?.slotId}
                    />
                  )}

                  <RegisterContactMail contactMail={detail.contactMail} />
                </>
              ) : (
                !!reservation && (
                  <>
                    {isAfterMaxDate && renderPastDeadline()}
                    <RegisterDetailContent detail={detail} reservation={reservation} setMode={setMode} />
                    {!isAfterMaxDate && <RegisterContactMail contactMail={detail?.contactMail} />}
                  </>
                )
              )}
            </Content>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default RegisterDetailScreen

const ScreenContainer = styled('div')`
  padding: 50px 75px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: ${CONTENT_WIDTH}px;
`

const ScreenTitle = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 36px;
`

const ErrorContainer = styled('div')`
  flex: 1;
  padding: 24px;
  align-items: center;
  justify-content: center;
`

const Text = styled('p')`
  margin: 0;
  padding: 0;
`

const Error = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SlotsTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 20px;
`

const SlotsTitle = styled(Text)`
  ${(props) => props.theme.fonts.h3Bold};
`

const WarningContainer = styled('div')`
  padding: 32px 0 16px;
`

// Animation already booked

const AlreadyBookedContainer = styled('div')`
  padding: 16px 0px;
  gap: 10px;
  margin: 0px;
`

const AlreadyBookedItem = styled('div')`
  padding: 12px 8px;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.transparentYellow};
`

const AlreadyBookedLabel = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
  display: inline;
`

const AlreadyBookedValue = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.raspberry};
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.raspberry};
  display: inline;
  cursor: pointer;
`

// Subscribe to push notifications

const SubscribeContainer = styled('div')`
  padding: 20px 0px;

  @media only screen and (min-width: ${breakpoints.small}px) {
    align-items: flex-start;
  }
`

const SubscribeDescriptionContainer = styled('div')`
  padding: 12px 8px;
  margin-bottom: 12px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 122, 112, 0.15);
`

const SubscribeDescriptionLabel = styled(Text)`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SubscribeButton = styled('button')`
  display: flex;
  flex-direction: row;
  padding: 9px 24px;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  border: 1px solid ${(props) => props.theme.colors.coral};
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

const SubscribeLabel = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.coral};
  margin-left: 16px;
  justify-content: center;
`

const PastDeadlineContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.warning}26;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-top: 28px;
`

const PastDeadlineInfosContainer = styled('div')`
  dispaly: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
`

const PastDeadLineTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0;
`

const PastDeadLineSubtitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin-top: 8px;
`

// Slot

const Content = styled('div')`
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin-right: 0px;
    flex: 1;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 0;
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin: 0 0 15px;
  }
`

const Resume = styled('p')`
  padding-top: 16px;
  ${(props) => props.theme.fonts.h3};
`
