import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import Category from 'components/label/Category'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as UserStore from 'store/user/user'

import { getDateString, getInvitationCardDesign, getUserName } from './utils'
import { breakpoints } from 'utils/breakpoints'
import { dateStringToZonedTime } from 'utils/dateUtils'

interface Props {
  type: RegisterInvitationType
  cardType?: AnimationCardType
  booking: Booking
  qrCode: string
  displayName?: string
}

const CARD_WIDTH = 264
const CARD_HEIGHT = 404
const CARD_RATIO = CARD_WIDTH / CARD_HEIGHT
const DEFAULT_CARD_TYPE = 'GOLDEN_INVITATION'

const AnimationCard = ({ type, cardType = DEFAULT_CARD_TYPE, booking, qrCode, displayName }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const user = useReducer(UserStore.store, (s) => s.user)

  const bookingDateLabel = React.useMemo(() => getDateString(i18n, booking.startDate, undefined, true), [booking])

  const bookingTimeLabel = React.useMemo(
    () =>
      i18n.t('screens.register.detail.timeSlot', {
        start: dateStringToZonedTime(booking.startDate),
        end: dateStringToZonedTime(booking.endDate),
      }),
    [booking]
  )

  const userName = React.useMemo(() => getUserName(user), [user])

  // Image du carton d'invitation avec les infos du créneau réservé
  const renderCard = () => {
    const { color, image, shadow } = getInvitationCardDesign(cardType)
    return (
      <CardMainContainer borderColor={color} shadow={shadow}>
        <CardBackgroundImg src={image} alt="" />
        <CardLogo src={require('assets/logo_short.png').default} alt="logo" />
        <CardContentContainer borderColor={color}>
          <CardTitle>{booking.animationName}</CardTitle>
          {!!userName && <CardUsername>{userName}</CardUsername>}
          <CardInfosContainer>
            <CardInfo>{bookingDateLabel}</CardInfo>
            <CardInfo>{bookingTimeLabel}</CardInfo>
            <CardInfo>{booking.slotCampus.name}</CardInfo>
            <CardInfo>{booking.slotPlace}</CardInfo>
          </CardInfosContainer>
        </CardContentContainer>
      </CardMainContainer>
    )
  }

  // QR code avec les infos du créneau réservé
  const renderQrCode = () => (
    <QrCodeInfoContainer>
      <QrCode src={`data:image/jpeg;base64,${qrCode}`} />
      {booking.status === 'FAMILY' && <QrCodeName>{displayName}</QrCodeName>}
      <QrCodeTitle>{booking.animationName}</QrCodeTitle>
      <ItemDate>{`${bookingDateLabel} • ${bookingTimeLabel}`}</ItemDate>
      <ItemPlaceContainer>
        {!!booking.slotPlace && (
          <Category
            icon="compass"
            label={booking.slotPlace}
            font="link"
            iconSize={15}
            iconColor={theme.colors.iconicGrey}
          />
        )}
      </ItemPlaceContainer>
    </QrCodeInfoContainer>
  )

  return (
    <ModalContainer>
      {type === 'CARTON_INVITATION' ? renderCard() : renderQrCode()}
      <DrawerButtonContainer>
        <Button label={i18n.t('common.close')} onClick={Modal.close} />
      </DrawerButtonContainer>
    </ModalContainer>
  )
}

export default AnimationCard

const DrawerButtonContainer = styled('div')`
  min-width: 90px;

  @media only screen and (min-width: ${breakpoints.small}px) {
    align-items: center;
    justify-content: flex-end;
  }
`

const ModalContainer = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
`

// INVITATION CARD

const CardMainContainer = styled('div')<{ borderColor: string; shadow: string }>`
  position: relative;
  width: 100%;
  max-height: ${CARD_HEIGHT}px;
  max-width: ${CARD_WIDTH}px;
  aspect-ratio: ${CARD_RATIO};
  overflow: hidden;
  justify-content: space-between;
  margin-bottom: 50px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.borderColor};
  box-shadow: ${(props) => props.shadow};
`

const CardBackgroundImg = styled('img')`
  position: absolute;
  z-index: 0;
  border-radius: 20px;
  object-fit: cover;
`

const CardLogo = styled('img')`
  z-index: 1;
  height: 16px;
  width: 88px;
  margin-top: 18px;
  align-self: center;
`

const CardContentContainer = styled('div')<{ borderColor: string }>`
  margin: 10px;
  padding: 10px 14px;
  border-radius: 7px;
  border: 1px solid ${(props) => props.borderColor};
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background: linear-gradient(220deg, rgba(0, 0, 0, 0.3) 15.1%, rgba(0, 0, 0, 0.21) 100%);
`

const CardInfosContainer = styled('div')`
  margin-top: 60px;
  gap: 4px;
`

const CardTitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  font-size: 17px;
`

const CardUsername = styled('p')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0;
  margin-top: 4px;
  text-transform: uppercase;
`

const CardInfo = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.white};
  line-height: 17px;
`

// QR CODE

const QrCodeInfoContainer = styled('div')`
  align-items: center;
`

const QrCode = styled('img')`
  height: 196px;
  width: 196px;
  border-radius: 10px;
`

const QrCodeName = styled('p')`
  ${(props) => props.theme.fonts.h2};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const QrCodeTitle = styled('p')`
  ${(props) => props.theme.fonts.h1Bold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 10px 0px 16px;
  text-align: center;
`

const ItemPlaceContainer = styled('div')`
  margin: 8px 0px 28px;
`

const ItemDate = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.functionalities.register};
  font-size: 12px;
  margin: 0px 8px 0px 0px;
`
