import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Loader from 'components/status/Loader'
import Button from 'components/button/Button'
import Category from 'components/label/Category'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as RegisterStore from './store'
import * as ThemeStore from 'theme/store'

import config from 'core/src/config'

import { Link } from 'react-router-dom'

import { capitalize } from 'utils/stringUtils'
import useBreakpoints from 'utils/useBreakpoints'
import { breakpoints } from 'utils/breakpoints'

const MIN_WIDTH = 200
const MAX_WIDTH = 800

export type BookingStatus = 'past' | 'future'

interface Props {
  screenStatus?: ScreenStatus
  pastScreenStatus?: ScreenStatus
  bookingsStatus: BookingStatus
  onBookingsStatusChange: (status: BookingStatus) => void
}

const MyReservationsList = ({
  screenStatus = 'ok',
  pastScreenStatus = 'ok',
  bookingsStatus,
  onBookingsStatusChange,
}: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const [, bp] = useBreakpoints()

  const reservations = useReducer(RegisterStore.store, (s) => s.reservations)
  const pastReservations = useReducer(RegisterStore.store, (s) => s.pastReservations)
  const drawerBehavior = useReducer(ThemeStore.store, (s) => s.drawer)

  const [status, setStatus] = React.useState<ScreenStatus>(screenStatus)
  const [pastStatus, setPastStatus] = React.useState<ScreenStatus>(pastScreenStatus)
  const [futureBookings, setFutureBookings] = React.useState<Booking[]>([])
  const [pastBookings, setPastBookings] = React.useState<Booking[]>([])

  React.useEffect(() => setStatus(screenStatus), [screenStatus])

  React.useEffect(() => setPastStatus(pastScreenStatus), [pastScreenStatus])

  React.useEffect(() => {
    if (!!reservations) {
      setFutureBookings(reservations.sort((a, b) => a.startDate.localeCompare(b.startDate)))
    }
  }, [reservations])

  React.useEffect(() => {
    if (!!pastReservations) {
      setPastBookings(pastReservations.sort((a, b) => b.startDate.localeCompare(a.startDate)))
    }
  }, [pastReservations])

  const renderItem = (item: Booking) => {
    const dateLabel = capitalize(
      i18n.t('screens.register.detail.fullDateSlot', {
        start: new Date(item.startDate),
        end: new Date(item.endDate),
      })
    )
    const timeLabel = i18n.t('screens.home.featureDetail.timeSlot', {
      start: new Date(item.startDate),
      end: new Date(item.endDate),
    })
    const isCancelled = !['BOOKED', 'SHARED', 'FAMILY'].includes(item.status)

    return (
      <ItemList key={item.animationId + item.id}>
        <ItemLink
          style={{ pointerEvents: !isCancelled ? 'auto' : 'none', cursor: !isCancelled ? 'pointer' : 'auto' }}
          to={`/register/${item.animationId}/${item.id}`}
          aria-label={i18n.t('accessibility.ariaLabels.register.goToReservation', { title: item.animationName })}>
          <ItemImage
            isCancelled={isCancelled}
            src={
              item.animationImage
                ? config.SERVER_PREFIX + item.animationImage
                : require('core/src/assets/news_default.jpeg').default
            }
          />

          <Infos>
            {isCancelled && (
              <ItemStatusContainer>
                <ItemStatus>{i18n.t(`screens.myReservations.status.${item.status}`)}</ItemStatus>
              </ItemStatusContainer>
            )}
            <ItemDate isCancelled={isCancelled}>
              {i18n.t('screens.register.tag', {
                date: dateLabel,
                place: item.slotPlace,
              })}
            </ItemDate>
            <ItemTitle isCancelled={isCancelled}>{item.animationName}</ItemTitle>
            {(bp === 'phone' || bp === 'small' || bp === 'medium' || bp === 'mediumBig') && (
              <TimeContainer>
                <TimeText>{timeLabel}</TimeText>
              </TimeContainer>
            )}
            {!!item.sharedBy ? (
              <Category
                icon="people"
                label={i18n.t('screens.register.detail.sharing.sharedBy', { name: item.sharedBy })}
                font="subtitle"
              />
            ) : (
              !!item.sharedToUsers && (
                <Category
                  icon="people"
                  label={i18n.t('screens.register.detail.sharing.sharedTo', {
                    name: item.sharedToUsers.join(', '),
                    count: item.sharedToUsers.length,
                  })}
                  font="subtitle"
                />
              )
            )}
          </Infos>

          {bp === 'big' && (
            <TimeContainer marginRight={isCancelled}>
              <TimeText>{timeLabel}</TimeText>
            </TimeContainer>
          )}

          {!isCancelled && <Icon name="chevron_right" size={24} color={theme.colors.functionalities.register} />}
        </ItemLink>
      </ItemList>
    )
  }

  return (
    <>
      <Container>
        {status === 'loading' ? (
          <Loader />
        ) : status === 'error' ? (
          <EmptyContainer>
            <Empty>{i18n.t('screens.myReservations.error')}</Empty>
          </EmptyContainer>
        ) : (
          <List>
            {(futureBookings.length === 0 && bookingsStatus === 'future') ||
            (pastBookings.length === 0 && bookingsStatus === 'past') ? (
              <EmptyContainer>
                <IconContainer>
                  <Icon name="register" size={24} color={theme.colors.primaryDark} />
                </IconContainer>
                <Empty>{i18n.t('screens.myReservations.empty')}</Empty>
              </EmptyContainer>
            ) : bookingsStatus === 'future' ? (
              futureBookings.map(renderItem)
            ) : pastStatus === 'loading' ? (
              <Loader />
            ) : pastStatus === 'error' ? (
              <EmptyContainer>
                <Empty>{i18n.t('screens.myReservations.errorPast')}</Empty>
              </EmptyContainer>
            ) : (
              pastBookings.map(renderItem)
            )}
          </List>
        )}
      </Container>
      <AbsoluteButton drawerIsOpen={drawerBehavior === 'open'}>
        <Button
          label={i18n.t(`screens.register.${bookingsStatus === 'past' ? 'future' : 'history'}`)}
          style="secondary"
          onClick={() => onBookingsStatusChange(bookingsStatus === 'past' ? 'future' : 'past')}
        />
      </AbsoluteButton>
    </>
  )
}

export default MyReservationsList

const Container = styled('div')`
  padding-bottom: 40px;
`

// SECTIONS LIST

const List = styled('ul')`
  list-style: none;
  padding: 0px;
  margin: 0px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    padding-bottom: 30px;
  }
`

const ItemList = styled('li')`
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
`

const ItemImage = styled('img')<{ isCancelled: boolean }>`
  object-fit: cover;
  user-select: none;
  border-radius: 12px;
  aspect-ratio: 4/3;
  height: auto;
  width: 150px;
  background-color: ${(props) => props.theme.colors.disable};
  opacity: ${(props) => (props.isCancelled ? 0.5 : 1)};
  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100px;
  }
`

const ItemLink = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  transition: all 0.1s ease-in-out;
  border-radius: 12px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);

  margin-bottom: 24px;

  padding: 20px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }

  :hover ${ItemImage} {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  :hover {
    transform: scale(1.02);
  }
`

const Infos = styled('div')`
  display: flex;
  flex: 1;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
`

const Text = styled('p')<{ isCancelled: boolean }>`
  margin: 0px;
  padding: 0px;
  opacity: ${(props) => (props.isCancelled ? 0.5 : 1)};
`

const ItemDate = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.functionalities.register};
  font-size: 15px;
`

const ItemTitle = styled(Text)`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 8px 0px;
  line-height: 19px;
`

const ItemStatusContainer = styled('div')`
  align-self: flex-start;
  max-width: 300px;
  margin-bottom: 12px;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const ItemStatus = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  line-height: 14px;
  margin: 0px;
  text-align: center;
`

const EmptyContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 24px 0px;
  align-items: center;
  justify-content: center;
`

const Empty = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const IconContainer = styled('div')`
  padding: 32px;
  margin-right: 24px;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border-radius: 12px;
`

const TimeContainer = styled('div')<{ marginRight?: boolean }>`
  border-radius: 12px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.contentBackground};
  margin-right: ${(props) => (props.marginRight ? '48px' : '24px')};
  width: 110px;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin-right: 0px;
    margin-top: 8px;
  }
`

const TimeText = styled('p')`
  ${(props) => props.theme.fonts.body};
`

const AbsoluteButton = styled('div')<{ drawerIsOpen: boolean }>`
  position: absolute;
  bottom: 20px;
  left: ${(props) => `calc(50% + ${props.drawerIsOpen ? '150px' : '50px'})`};
  transform: translate(-50%, -50%);
  @media only screen and (max-width: ${breakpoints.phone}px) {
    left: 50%;
    bottom: 0px;
  }
`
